.download-button {
  display: inline-flex;
  margin: 4px;
  width: fit-content;
}

.download-icon {
  margin-right: 8px;
}

.link {
  color: #345766;
  text-decoration: underline;
}
