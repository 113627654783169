.bg-header {
  background: url("/public/deejkikkalsunset.png") center center no-repeat;
  background-attachment: local;
  background-size: cover;
}

.bg-header2 {
  /* background: url("/public/TheProposal1.jpg") center center no-repeat; */
  background-attachment: fixed;
  background-size: cover;
}

.bg-header3 {
  /* background: url("/public/TheProposal3.jpg") center center no-repeat; */
  background-attachment: fixed;
  background-size: cover;
}

.bg-header3:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(256, 256, 256, 0.5);
}

/* Countdown css */

.container {
  position: relative;
  margin: 50px auto auto auto;
  overflow: hidden;
  height: 480px;
}

@media (min-width: 768px) {
  .container {
    width: 650px;
  }

  .seconds {
    margin-top: 0px;
  }
}

h1 {
  font-family: "Lato", sans-serif;
  text-align: center;
  margin-top: 2rem;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: black;
}

#timer {
  color: black;
  text-align: center;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-size: 0.7rem;
  letter-spacing: 5px;
  margin-top: 25%;
}

.days,
.hours,
.minutes,
.seconds {
  display: inline-block;
  padding: 20px;
  width: 135px;
  border-radius: 5px;
  font-size: 0.9rem;
}

.days {
  background: #f1b4bc;
}

.hours {
  background: #e0929e;
  color: black;
}

.minutes {
  background: #b8cfd9;
}

.seconds {
  background: #6e8f9d;
  margin-top: 10px;
}

.numbers {
  color: black;
  font-size: 3rem;
}

.white {
  position: absolute;
  background: #e0929e;
  height: 85px;
  width: 75px;
  left: 30%;
  top: 2%;
  .triangle {
    border-bottom: 14px solid #e0929e;
  }
  .string {
    background: #e0929e;
    border: 1px solid #e0929e;
  }
}

.red {
  position: absolute;
  background: #f1b4bc;
  left: 18%;
  top: 9%;
  height: 65px;
  width: 70px;
  .triangle {
    border-bottom: 14px solid #f1b4bc;
  }
  .string {
    background: #f1b4bc;
    border: 1px solid #f1b4bc;
  }
}

.blue {
  position: absolute;
  background: #b8cfd9;
  height: 80px;
  width: 80px;
  left: 60%;
  top: 5%;
  .triangle {
    border-bottom: 14px solid #b8cfd9;
  }
  .string {
    background: #b8cfd9;
    border: 1px solid #b8cfd9;
  }
}

.balloon {
  /* border: 1px solid #000; */
  border-radius: 50% 50% 50% 50%/ 40% 40% 60% 60%;
  z-index: 2;
}

.eye {
  position: absolute;
  width: 7px;
  height: 7px;
  top: 40%;
  left: 22%;
  background: #000;
  border-radius: 50%;
  &:after {
    content: "";
    left: 35px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000;
    position: absolute;
  }
}

.mouth {
  position: absolute;
  top: 45%;
  left: 43%;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.happy {
  border: 2px solid;
  border-color: transparent #000 #000 transparent;
  transform: rotate(45deg);
}

.triangle {
  position: absolute;
  left: 40%;
  bottom: -10%;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}

.string {
  position: absolute;
  height: 70px;
  width: 1px;
  left: 48%;
  top: 100%;
  z-index: -1;
}

.star {
  width: 20px;
  height: 20px;
  background: #345766;
  -webkit-clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.star-red {
  width: 30px;
  height: 30px;
  margin-left: 51px;
  margin-top: -5px;
  background: #f1b4bc;
  -webkit-clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}
