.event-image {
  height: 250px;
  width: 250px;
}

pre {
  background: black;
  color: white;
  padding: 1rem;
  overflow: auto;
  border-radius: 10px;
}

pre code {
  text-align: left;
  white-space: pre-wrap;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
}

.timeline-container {
  padding: 0 25px;
  margin-left: 100px;
  /* margin: 0 60px; */

  ul {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -20px;
      height: 100%;
      border: 1px dashed #aaa;
    }
  }
}
.box {
  margin: 50px 0;
  padding: 10px 20px;
  background: #b8cfd9;
  position: relative;
  letter-spacing: 2px;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(100, 100, 100, 0.3);
  > span {
    content: "";
    position: absolute;
    top: 0;
    left: -20px;
    height: 100%;
    border: 1px solid #ccc;
    &:before,
    &:after {
      content: "";
      position: absolute;
      left: -7px;
      width: 10px;
      height: 10px;
      border: 2px solid #ccc;
      background: #01579b;
      border-radius: 100%;
    }
    &:before {
      top: -12px;
    }
    &:after {
      top: 100%;
    }
  }
  .title {
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 10px;
  }
  .sub-title {
    font-size: 0.8rem;
    color: black;
  }
  .info {
    margin: 15px 0;
  }
  .time {
    span {
      position: absolute;
      font-size: 0.8rem;
      color: darkgray;
      &:first-child {
        top: -15px;
        left: -90px;
      }
      &:last-child {
        top: 100%;
        left: -90px;
      }
    }
  }
}

.rem-63 {
  max-width: 63rem;
}
