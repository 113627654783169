.contact-us:before,
.contact-us:after {
  box-sizing: border-box;
}

.contact-us {
  font: 400 16px Montserrat, sans-serif;
  color: #444;
  padding: 0;
  margin: 0;
}

.contact-us {
  display: flex;
  min-height: 80vh;
  align-items: center;
  justify-content: center;
}

.contact-us .card {
  width: 600px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(gray, 0.06);
  overflow: hidden;
}

.contact-us .waves {
  height: 80px;
  background: linear-gradient(to bottom left, gray, gray);
}

.contact-us h1 {
  margin: 2rem 2rem 0;
  font-size: 1.62rem;
}

.contact-us .form {
  padding: 2rem;
}

.contact-us .button {
  display: block;
  width: 100%;
  background-color: #e0929e;
  border: none;
  border-radius: 3px;
  padding: 1ch;
  font-size: 1rem;
  color: #fff;
  font-family: inherit;
}

.contact-us .button:hover {
  background-color: #ec4899;
}

.contact-us .button:disabled {
  background-color: lightgray;
}

.contact-us .text-input {
  position: relative;
  margin-bottom: 1rem;
  label {
    position: absolute;
    top: 1ch;
    left: 1ch;
    padding: 0 0.5ch;
    pointer-events: none;
    transition: 150ms ease;
    color: #888;
  }
  label.label-focus {
    position: absolute;
    top: 0;
    font-size: 0.9rem;
    color: #e0929e;
    font-weight: bold;
    transform: translateY(-50%);
    background-color: #fff;
    font-weight: 600;
  }
  input {
    display: block;
    width: 100%;
    border: 2px solid #eee;
    padding: 1ch;
    border-radius: 3px;
    transition: 150ms ease;
    outline: none;
    font-size: 1rem;
    font-family: inherit;
  }
  input.input-focus {
    border-color: #e0929e;
  }
}

.contact-us .text-area {
  position: relative;
  margin-bottom: 1rem;
  label {
    position: absolute;
    top: 1ch;
    left: 1ch;
    padding: 0 0.5ch;
    pointer-events: none;
    transition: 150ms ease;
    color: #888;
  }
  label.label-focus {
    position: absolute;
    top: 0;
    font-size: 0.9rem;
    color: #e0929e;
    font-weight: bold;
    transform: translateY(-50%);
    background-color: #fff;
    font-weight: 600;
  }
  textarea {
    display: block;
    width: 100%;
    border: 2px solid #eee;
    padding: 1ch;
    border-radius: 3px;
    transition: 150ms ease;
    outline: none;
    font-size: 1rem;
    font-family: inherit;
    height: 8rem;
  }
  textarea.input-focus {
    border-color: #e0929e;
  }
}
