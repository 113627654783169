/* Flip Book CSS */
.demoPage {
  background-color: white;
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
  color: black;
}

.cover-page {
  box-shadow: inset 0 0 30px 0 rgba(36, 10, 3, 0.5), -2px 0 5px 2px rgba(0, 0, 0, 0.4);
}

/* not implemented yet */
.left-page {
  box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
}

/* not implemented yet */
.right-page::after {
  box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
}

.page-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.left-page {
  .footer {
    text-align: left;
    margin: 0 0 10px 20px;
  }
}

.right-page {
  .footer {
    text-align: right;
    margin: 0 20px 10px 0;
  }
}
