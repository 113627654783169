/* Board */

.board {
  position: relative;
  width: calc(var(--pixel-size) * 8 * 4 + var(--pixel-size) * 5);
}

.game-over-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: lightgray;
  opacity: 90%;
  width: calc(var(--pixel-size) * 8 * 4 + var(--pixel-size) * 5);
  height: calc(var(--pixel-size) * 8 * 4 + var(--pixel-size) * 5);
  z-index: 4;
}

.grid-2048 {
  display: flex;
  flex-wrap: wrap;
  background: var(--secondary-background);
  border: calc(var(--pixel-size) * 0.5) solid var(--secondary-background);
  border-radius: calc(var(--pixel-size) * 0.75);
}

.cell {
  width: calc(var(--pixel-size) * 8);
  height: calc(var(--pixel-size) * 8);
  margin: calc(var(--pixel-size) * 0.5);
  border-radius: calc(var(--pixel-size) * 0.5);
  background: var(--cell-background);
}

.tiles {
  position: absolute;
  z-index: 2;
  margin: calc(var(--pixel-size) * 0.5);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: 512px) {
  .board {
    width: calc(var(--pixel-size) * 12.5 * 4 + var(--pixel-size) * 10);
  }

  .game-over-panel {
    width: calc(var(--pixel-size) * 12.5 * 4 + var(--pixel-size) * 10);
    height: calc(var(--pixel-size) * 12.5 * 4 + var(--pixel-size) * 10);
  }

  .grid-2048 {
    border: calc(var(--pixel-size) * 1) solid var(--secondary-background);
  }

  .cell {
    width: calc(var(--pixel-size) * 12.5);
    height: calc(var(--pixel-size) * 12.5);
    margin: calc(var(--pixel-size) * 1);
  }

  .tiles {
    margin: calc(var(--pixel-size) * 1);
  }
}

/* Tile */

.tile {
  position: absolute;
  width: calc(var(--pixel-size) * 8);
  height: calc(var(--pixel-size) * 8);
  margin: calc(var(--pixel-size) * 0.5);
  border-radius: calc(var(--pixel-size) * 0.5);
  background: var(--tile-background);
  color: var(--primary-text-color);
  font-size: calc(var(--pixel-size) * 4);
  line-height: calc(var(--pixel-size) * 8);
  font-weight: bold;
  text-align: center;
  transition-property: left, top, transform;
  transition-duration: 200ms, 200ms, 100ms;
}

@media (min-width: 512px) {
  .tile {
    width: calc(var(--pixel-size) * 12.5);
    height: calc(var(--pixel-size) * 12.5);
    margin: calc(var(--pixel-size) * 1);
    font-size: calc(var(--pixel-size) * 6);
    line-height: calc(var(--pixel-size) * 12.5);
  }
}

/* -=-=-=- START OF TEXT COLOR -=-=-=- */
.tile8,
.tile16,
.tile32,
.tile64,
.tile128,
.tile256,
.tile512,
.tile1024,
.tile2048,
.tile4096,
.tile8192 {
  color: var(--secondary-text-color);
}
/* -=-=-=-= END OF TEXT COLOR =-=-=-=- */

/* -=-=-=- START OF TEXT SIZE -=-=-=- */
.tile128,
.tile256,
.tile512 {
  font-size: calc(var(--pixel-size) * 3.25);
}

.tile1024,
.tile2048,
.tile4096,
.tile8192 {
  font-size: calc(var(--pixel-size) * 2.75);
}

@media (min-width: 512px) {
  .tile128,
  .tile256,
  .tile512 {
    font-size: calc(var(--pixel-size) * 5.5);
  }

  .tile1024,
  .tile2048,
  .tile4096,
  .tile8192 {
    font-size: calc(var(--pixel-size) * 4);
  }
}
/* -=-=-=-= END OF TEXT SIZE =-=-=-=- */

.tile2 {
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
}

.tile4 {
  background: #ede0c8;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0), inset 0 0 0 1px rgba(255, 255, 255, 0);
}

.tile8 {
  background: #f2b179;
}

.tile16 {
  background: #f59563;
}

.tile32 {
  background: #f67c5f;
}

.tile64 {
  background: #f65e3b;
}

.tile128 {
  background: #edcf72;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.2381),
    inset 0 0 0 1px rgba(255, 255, 255, 0.14286);
}

.tile256 {
  background: #edcc61;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.31746),
    inset 0 0 0 1px rgba(255, 255, 255, 0.19048);
}

.tile512 {
  background: #edc850;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.39683),
    inset 0 0 0 1px rgba(255, 255, 255, 0.2381);
}

.tile1024 {
  background: #edc53f;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.47619),
    inset 0 0 0 1px rgba(255, 255, 255, 0.28571);
}

.tile2048,
.tile4096,
.tile8192 {
  background: #edc22e;
  box-shadow: 0 0 30px 10px rgba(243, 215, 116, 0.55556),
    inset 0 0 0 1px rgba(255, 255, 255, 0.33333);
}

/* Score */

.score {
  background: var(--secondary-background);
  border: calc(var(--pixel-size) * 0.5) solid var(--secondary-background);
  border-radius: calc(var(--pixel-size) * 0.75);
  color: var(--tile-background);
  font-size: calc(var(--pixel-size) * 1.5);
  line-height: calc(var(--pixel-size) * 3);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.score-offset {
  padding-left: 20px;
}

.score > div {
  font-size: calc(var(--pixel-size) * 2.5);
  color: var(--secondary-text-color);
}

@media (min-width: 512px) {
  .score {
    border: calc(var(--pixel-size) * 1) solid var(--secondary-background);
    font-size: calc(var(--pixel-size) * 2);
    line-height: calc(var(--pixel-size) * 3.5);
  }

  .score > div {
    font-size: calc(var(--pixel-size) * 3.5);
  }
}

/* Start leaderboard modal */
.ReactModal__Overlay--after-open {
  z-index: 5;
}

#leaderboard {
  width: 100%;
  position: relative;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  color: #141a39;
  cursor: default;
}

tr {
  transition: all 0.2s ease-in-out;
  border-radius: 0.2rem;
}

tr:not(:first-child):hover {
  background-color: #fff;
  transform: scale(1.1);
  -webkit-box-shadow: 0px 5px 15px 8px #e4e7fb;
  box-shadow: 0px 5px 15px 8px #e4e7fb;
}

tr:nth-child(odd) {
  background-color: #f9f9f9;
}

tr:nth-child(1) {
  color: #fff;
}

td {
  height: 5rem;
  font-family: "Rubik", sans-serif;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  position: relative;
}

.number {
  width: 1rem;
  font-size: 2.2rem;
  font-weight: bold;
  text-align: left;
}

.name {
  text-align: left;
  font-size: 1.2rem;
}

.points {
  font-weight: bold;
  font-size: 1.3rem;
  display: flex;
  justify-content: start;
  align-items: center;
}

.points:first-child {
  width: 10rem;
}

.gold-medal {
  height: 3rem;
  margin-left: 1.5rem;
}

.ribbon {
  width: 69vw;
  height: 5.5rem;
  top: -0.5rem;
  background-color: #b86b77;
  position: absolute;
  left: -1rem;
  -webkit-box-shadow: 0px 15px 11px -6px #7a7a7d;
  box-shadow: 0px 15px 11px -6px #7a7a7d;
}

.ribbon::before {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  bottom: -0.8rem;
  left: 0.35rem;
  transform: rotate(45deg);
  background-color: #b86b77;
  position: absolute;
  z-index: -1;
}

.ribbon::after {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  bottom: -0.8rem;
  right: 0.35rem;
  transform: rotate(45deg);
  background-color: #b86b77;
  position: absolute;
  z-index: -1;
}

#buttons {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.exit {
  width: 11rem;
  height: 3rem;
  font-family: "Rubik", sans-serif;
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #7e7f86;
  border: 0;
  background-color: #fff;
  border-radius: 2rem;
  cursor: pointer;
}

.exit:hover {
  border: 0.1rem solid #b86b77;
}

.continue {
  width: 11rem;
  height: 3rem;
  font-family: "Rubik", sans-serif;
  font-size: 1.3rem;
  color: #fff;
  text-transform: uppercase;
  background-color: #b86b77;
  border: 0;
  border-bottom: 0.2rem solid #3838b8;
  border-radius: 2rem;
  cursor: pointer;
}

.continue:active {
  border-bottom: 0;
}

/* @media (max-width: 740px) {
  * {
    font-size: 70%;
  }
}

@media (max-width: 500px) {
  * {
    font-size: 55%;
  }
}

@media (max-width: 390px) {
  * {
    font-size: 45%;
  }
} */
/* End leaderboard modal */

/* Name modal */
.name-modal-contact-us {
  min-height: auto !important;
  flex-direction: column;
}

.name-modal-text-input {
  outline: none !important;
  border: 2px solid #e0929e;
  border-radius: 3px;
  padding: 1ch;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 15px;
  height: 40px;
}
.name-modal-text-input:focus-visible {
  border: 2px solid #e0929e;
}

.name-modal-send-button {
  display: block;
  width: 100%;
  background-color: #e0929e;
  border: none;
  border-radius: 3px;
  padding: 1ch;
  font-size: 1rem;
  color: #fff;
  font-family: inherit;
}

.name-modal-send-button:hover {
  background-color: #ec4899;
}

.name-modal-send-button:disabled {
  background-color: lightgray;
}

/* Index */

.twenty48 {
  margin: calc(var(--pixel-size) * 0) auto;
  padding: calc(var(--pixel-size) * 1);
  width: 100%;
  max-width: calc(var(--pixel-size) * 37);
}

@media (min-width: 512px) {
  .twenty48 {
    max-width: calc(var(--pixel-size) * 60);
  }
}

.twenty48 > header {
  display: flex;
  align-items: center;
  margin: calc(var(--pixel-size) * 1) calc(var(--pixel-size) * 0);
}

.twenty48 > header > * {
  flex: 1;
}

.twenty48 > footer {
  margin: calc(var(--pixel-size) * 3) calc(var(--pixel-size) * 0);
  font-weight: 600;
  text-align: center;
}

.twenty48 > footer > .socials {
  display: flex;
  justify-content: center;
  margin-bottom: calc(var(--pixel-size) * 2);
}

.twenty48 > footer > .socials > a > img {
  vertical-align: middle;
  margin: calc(var(--pixel-size) * 0.5);
}

@media (min-width: 512px) {
  .twenty48 > footer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    text-align: left;
  }

  .twenty48 > footer > div {
    width: 100%;
  }

  .twenty48 > footer > .socials {
    justify-content: right;
    margin-bottom: calc(var(--pixel-size) * 0);
  }

  .twenty48 > footer > .socials > a > img:last-child {
    margin: calc(var(--pixel-size) * 1);
    margin-right: calc(var(--pixel-size) * 0);
  }
}

/* Global */

:root {
  --pixel-size: 8px;

  /* Backgrounds */
  --primary-background: #faf8ef;
  --secondary-background: #6e8f9d;
  --cell-background: #b8cfd9;
  --tile-background: #eee4da;

  /* Colors */
  --primary-text-color: #776e65;
  --secondary-text-color: #f9f6f2;
}

.twenty48 {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: var(--primary-text-color);
  font-size: calc(var(--pixel-size) * 2);
  line-height: calc(var(--pixel-size) * 3.5);
}

.twenty48 a {
  color: var(--primary-text-color);
  font-weight: bold;
}

.twenty48 a:hover,
a:focus {
  opacity: 0.8;
}

.twenty48 h1 {
  font-size: calc(var(--pixel-size) * 5);
  line-height: calc(var(--pixel-size) * 5);
  margin: calc(var(--pixel-size) * 1.5) calc(var(--pixel-size) * 0);
}

.twenty48 h2 {
  font-size: calc(var(--pixel-size) * 2.5);
  line-height: calc(var(--pixel-size) * 3.75);
  margin: calc(var(--pixel-size) * 2.5) calc(var(--pixel-size) * 0);
}

.twenty48 h2 > span {
  display: block;
}

@media (min-width: 512px) {
  .twenty48 h1 {
    font-size: calc(var(--pixel-size) * 8.5);
    line-height: calc(var(--pixel-size) * 8.5);
    margin: calc(var(--pixel-size) * 2.25) calc(var(--pixel-size) * 0);
  }

  .twenty48 h2 > span {
    display: inline;
  }
}
