@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  cursor: url("/public/cursor.ico"), default;
}

a:hover {
  cursor: url("/public/click.ico"), pointer;
}

button:hover {
  cursor: url("/public/click.ico"), pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.by-date {
  position: relative;
  height: 0;
  font-size: 0.8rem;
}

.by-date-mobile {
  font-size: 1rem;
  padding-top: 8px;
}

.log-out-button {
  margin-left: 24px;
}

.tooltip {
  background-color: black;
  color: white;
  padding: 4px;
  border-radius: 5px;
  z-index: 11;
}

.password-background {
  min-height: 100vh;
  min-width: 100vw;
}

.password-container {
  display: flex;
  justify-content: center;
  align-items: auto;
  align-items: center;
  height: 100vh;
}

.password-label {
  font-size: 1rem;
  margin-right: 10px;
}

.password-input {
  color: black;
  font-size: 2rem;
  border: 1px solid lightgray;
  min-height: 50px;
  border-radius: 5px;
  max-width: 40vw;
}

.password-input:focus {
  border: 1px solid #e0929e;
  outline: none;
}

.password-reveal {
  cursor: pointer;
  width: 25px;
  display: flex;
  vertical-align: sub;
  position: relative;
  right: 34px;
  height: 25px;
}

.password-submit {
  min-height: 50px;
  vertical-align: super;
}
